import { useMutation, useQueryClient } from "@tanstack/react-query";

import { requests } from "api";
import { LegalCabinetGatewayV1CreateVehicleRequest } from "api/generated";

import { COMPANY_VEHICLES_QUERY_KEY } from "./useCompanyVehiclesQuery";

export const useCreateVehicleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: LegalCabinetGatewayV1CreateVehicleRequest) =>
      requests.legalCabinetGatewayCreateVehicle(body),
    onSuccess: (data) => {
      if (!data.data.error) {
        queryClient.invalidateQueries({ queryKey: COMPANY_VEHICLES_QUERY_KEY });
      }
    }
  });
};
